const cards = [
   {
      id: "img01",
      target: "",
      alt: "Conjunto Clássico",
      title: "Conjunto Clássico",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Conjunto Dinamarca",
      title: "Conjunto Dinamarca",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Conjunto Kodus",
      title: "Conjunto Kodus",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Conjunto Marros",
      title: "Conjunto Marros",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Conjunto Roma",
      title: "Conjunto Roma",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Conjunto Sofá base madeira e corda",
      title: "Conjunto Sofá",
      description: "Base madeira e corda",
   },
   {
      id: "img07",
      target: "",
      alt: "Conjunto Sofá",
      title: "Conjunto Sofá",
      description: "Base alumínio",
   },
   {
      id: "img08",
      target: "",
      alt: "Conjunto Sofá base madeira",
      title: "Conjunto Sofá",
      description: "Base madeira",
   },
   {
      id: "img09",
      target: "",
      alt: "Conjunto Stilo",
      title: "Conjunto Stilo",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Conjunto Vinales",
      title: "Conjunto Vinales",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Corda mesa",
      title: "Corda mesa",
      description: "",
   },
   {
      id: "img12",
      target: "",
      alt: "Conjunto Sofá",
      title: "Conjunto Sofá",
      description: "",
   },
   {
      id: "img13",
      target: "",
      alt: "Conjunto Sofá",
      title: "Conjunto Sofá",
      description: "",
   },
   {
      id: "img14",
      target: "",
      alt: "Conjunto Sofá",
      title: "Conjunto Sofá",
      description: "",
   },
   {
      id: "img15",
      target: "",
      alt: "Poltrona Cacho",
      title: "Poltrona Cacho",
      description: "",
   },
   {
      id: "img16",
      target: "",
      alt: "Poltrona Caiobá",
      title: "Poltrona Caiobá",
      description: "",
   },
   {
      id: "img17",
      target: "",
      alt: "Poltrona Fita Naltica",
      title: "Poltrona Fita Naltica",
      description: "",
   },
   {
      id: "img18",
      target: "",
      alt: "Poltrona Kodus",
      title: "Poltrona Kodus",
      description: "",
   },
   {
      id: "img19",
      target: "",
      alt: "Poltrona Sol",
      title: "Poltrona Sol",
      description: "",
   },
   {
      id: "img20",
      target: "",
      alt: "Poltrona Vegas",
      title: "Poltrona Vegas",
      description: "",
   },
   {
      id: "img21",
      target: "",
      alt: "Poltrona Veneza",
      title: "Poltrona Veneza",
      description: "",
   },
   {
      id: "img22",
      target: "",
      alt: "Poltronas Kodus",
      title: "Poltronas Kodus",
      description: "",
   },
   {
      id: "img23",
      target: "",
      alt: "Puff Garden",
      title: "Puff Garden",
      description: "",
   },
   {
      id: "img24",
      target: "",
      alt: "Sofá Exclusivo",
      title: "Sofá Exclusivo",
      description: "",
   },
   {
      id: "img25",
      target: "",
      alt: "Sofá Kodus e Chá Bar Planner",
      title: "Sofá Kodus e Chá Bar Planner",
      description: "",
   },
   {
      id: "img26",
      target: "",
      alt: "Sofá Kodus em madeira e corda",
      title: "Sofá Kodus",
      description: "Em madeira e corda",
   },
   {
      id: "img27",
      target: "",
      alt: "Sofá Kodus Fita Larga",
      title: "Sofá Kodus Fita Larga",
      description: "",
   },
   {
      id: "img28",
      target: "",
      alt: "Sofá Kodus em madeira e corda",
      title: "Sofá Kodus",
      description: "Em madeira e corda",
   },
   {
      id: "img29",
      target: "",
      alt: "Sofá Portinare e Puff Garden",
      title: "Sofá Portinare e Puff Garden",
      description: "",
   },
   {
      id: "img30",
      target: "",
      alt: "Sofá Stilo base alumínio",
      title: "Sofá Stilo",
      description: "Base alumínio",
   },
   {
      id: "img31",
      target: "",
      alt: "Sofá Stilo com madeira",
      title: "Sofá Stilo",
      description: "Com madeira",
   },
   {
      id: "img32",
      target: "",
      alt: "Sofás Fita Naltica",
      title: "Sofás Fita Naltica",
      description: "",
   },
   {
      id: "img33",
      target: "",
      alt: "Sofá Vinales Madeira",
      title: "Sofá Vinales Madeira",
      description: "",
   },
   {
      id: "img34",
      target: "",
      alt: "Sofá Acapulco 2",
      title: "Sofá Acapulco II",
      description: "",
   },
   {
      id: "img35",
      target: "",
      alt: "Sofá Del Mare-pepe-lima-James",
      title: "Sofá Del Mare",
      description: "Pepê Lima",
   },
   {
      id: "img36",
      target: "",
      alt: "Estofado Cosy-639-James",
      title: "Estofado Cosy",
      description: "",
   },
   {
      id: "img37",
      target: "",
      alt: "Poltrona Acapulco",
      title: "Poltrona Acapulco",
      description: "",
   },
   {
      id: "img38",
      target: "",
      alt: "Sofá Cosi",
      title: "Sofá Cosi",
      description: "",
   },
]

export { cards }